<template>
  <div class="shop-con">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <DiyTabar :comObj="tabbar"></DiyTabar> -->
    <BottomTar :bottomData="bottomData"></BottomTar>
  </div>
</template>

<script>
import axios from "axios";
import BottomTar from "@/views/all/tabbar";
export default {
  components: {
    BottomTar
  },
  data() {
    return {
      tabbar: {},
      group_page: {
        group_idx: -1,
        page_idx: -1
      },
      topSty: {
        background: "linear-gradient(-90deg,rgba(255, 128, 0, 1),rgba(255, 174, 0, 1))",
        color: '#ffffff',
      },
      bottomData: [
        {
          title :"首页",
          ico: "iconfont iconhome",
          path: "/shopindex"
        },
        {
          title: "分类",
          ico: "iconfont iconweibiaoti2010102",
          path: "/goods"
        },
        {
          title: "购物车",
          ico: "iconfont icongouwuche1",
          path: "/shopping"
        },
        {
          title: "订单",
          ico: "iconfont iconicon-dingdan",
          path: "/order"
        },
        {
          title: "我的",
          ico: "iconfont icongeren1",
          path: "/personal"
        }
      ],
    }
  },
  watch: {
    $route(to,from){
    console.log(to.query, from.query);
      if(to.query.hasOwnProperty("group_idx") == false) {
        this.getPageData(0, 0)
      } else {
        this.getPageData(to.query.group_idx, to.query.page_idx)
      }
    },
    group_page: {
      handler(newVal) {
        console.log(121212)
        this.getPageData(newVal.group_idx, newVal.page_idx)
      },
      deep: true
    }
  },
  methods: {
    getPageData(group_idx, page_idx) {
      var appid = sessionStorage.getItem("app_id");
      axios({
        method: 'post',
        url:"https://www.58zltc.com/api/page/group_list",
        header: "application/x-www-form-urlencoded",
        data: {
          app_id:appid
        }
      }).then(res => {
        this.tabbar = JSON.parse(res.data.data[group_idx].page_list[page_idx].tabbar);
      })
    },
  },
  mounted() {
    setTimeout(() => {
      if(this.$route.query.hasOwnProperty("group_idx")) {
        this.group_page.page_idx = this.$route.query.page_idx;
        this.group_page.group_idx = this.$route.query.group_idx;
      } else {
        this.group_page.page_idx = 0;
        this.group_page.group_idx = 0;
      }
    },100)
    
  }
}
</script>
<style lang="scss" scoped>
.shop-con {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #f4f4f4;
}
</style>
