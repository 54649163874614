import { render, staticRenderFns } from "./bottombar.vue?vue&type=template&id=03ff2662&scoped=true&"
import script from "./bottombar.vue?vue&type=script&lang=js&"
export * from "./bottombar.vue?vue&type=script&lang=js&"
import style0 from "./bottombar.vue?vue&type=style&index=0&id=03ff2662&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ff2662",
  null
  
)

export default component.exports