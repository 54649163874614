<template>
  <div class="tabars">
    <div v-for="(item, idx) in bottomData" :key="idx"
      :class="{ home_nav:true, current:isShow === idx }"
      @click.stop="change(idx)"
    >
      <p>
        <i :class="item.ico" aria-hidden="true"></i>
      </p>
      <p class="tabbar-name">{{item.title}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tarbar",
  props: {
    bottomData: {
      type: Array,
      default: function() {
        return [
          {
            title :"首页",
            ico: "iconfont iconhome",
            path: "/home"
          },
          {
            title: "论坛",
            ico: "iconfont icon141",
            path: "/forum"
          },
          {
            title: "消息",
            ico: "iconfont iconxiaoxi",
            path: "/message"
          },
          {
            title: "购物车",
            ico: "iconfont icongouwuche1",
            path: "/shopping"
          },
          {
            title: "我的",
            ico: "iconfont iconwode",
            path: "/personal"
          }
        ]
      }
    }
  },
  data() {
    return {
      isShow: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setRouterIdx();
    })
  },
  methods: {
    // 选中状态的修改
    change(idx) {
      this.isShow = idx;
      this.$router.push(this.bottomData[idx].path);
    },
    // 路由变化设置当前选中
    setRouterIdx() {
      var path = this.$route.path;
      switch (path) {
        case "viphome":
          this.isShow = 0;
          break;
        case "integralshop":
          this.isShow = 1;
          break;    
        case "vipShopList":
          this.isShow = 2;
          break;  
        case "vippersonal":
          this.isShow = 3;
          break;  
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.tabars {
  width: 100%;
  height: 45px;
  display: flex;
  position: fixed;
  align-items: center;
  padding: 6px 0;
  border-top: 0.5px solid #d8d8d8;
  background-color: #fff;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.home_nav {
  flex: 1;
  padding-top: 8px;
  text-align: center;
}
.home_nav p {
  line-height: 12.5px;
  i {
    font-size: 22px;
  }
}
.current {
  color: #f03d37;
}
.tabbar-name {
  margin-top: 6px;
}
</style>
